import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-comment-cell-renderer',
    imports: [],
    templateUrl: './comment-cell-renderer.component.html',
    styleUrl: './comment-cell-renderer.component.scss'
})
export class CommentCellRendererComponent {
  private params: any;
  public value: string | undefined;

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }
}
