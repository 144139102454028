import { Component, inject } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';
import { StorageService } from '../../../../services/storage.service';

@Component({
    selector: 'app-custom-status-bar',
    imports: [],
    templateUrl: './custom-status-bar.component.html',
    styleUrl: './custom-status-bar.component.scss'
})
export class CustomStatusBarComponent implements IStatusPanelAngularComp{
  private params!: IStatusPanelParams;
  public count: number = 0;
  private storageService: StorageService = inject(StorageService);

  agInit(params: IStatusPanelParams): void {
    this.params = params;
    this.params.api.addEventListener('gridReady', this.onGridReady.bind(this));
    this.params.api.addEventListener('modelUpdated', this.onGridReady.bind(this));
  }

  onGridReady() {
    // this.count = this.storageService.$gridRowsCount.value;
    this.count = this.params.api.getDisplayedRowCount();
  }
}
