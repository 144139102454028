import { Component, inject } from '@angular/core';
import {Router} from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { DialogService } from 'primeng/dynamicdialog';
import { LeadDetailsDialogComponent } from '../../../dialog/lead-details-dialog/lead-details-dialog.component';
import { StorageService } from '../../../../services/storage.service';
import { Button } from 'primeng/button';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-view-cell-render',
  imports: [Button],
  providers: [DialogService],
  templateUrl: './view-cell-render.component.html',
  styleUrl: './view-cell-render.component.scss',
})
export class ViewCellRenderComponent {
  private http = inject(HttpClient);
  public messageService: MessageService = inject(MessageService);
  private params: any;
  public value: string | undefined;
  private dialogService: DialogService = inject(DialogService);
  private routerService: Router = inject(Router);
  public storageService: StorageService = inject(StorageService);

  public form_id: string = '';
  public isFormException: boolean = false;
  public platform_id: string = '';
  public row_id: string = '';

  constructor(private router: Router) {}
  agInit(params: any): void {
    this.params = params;
    this.isFormException = this.params.isFormException;
    this.platform_id = this.params.platform_id;
    this.row_id = this.params.row_id;
    this.form_id = this.params.data.id;
    this.value = params.value;
  }
  // "65c35f94a62535888e895ec4"

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }

  onClickException() {
    const data = {
      platform_id: this.platform_id,
      field: 'update-form',
      value: {
        id: this.params.data.id,
        exception: !this.value
      },
    };

    this.storageService.$overviewShowRowLoader.next(this.row_id);

    this.http.post(environment.API_PATH + `/analytics/overview`, data).subscribe({
      next: async (res: any) => {
        this.storageService.$overviewGridUpdate.next(res)
        this.messageService.add({
          severity: 'success',
          summary: 'Overview Update',
          detail: 'Form Exceptions Data Successfully Updated',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Overview Update Error!',
          detail: err.message,
        });
      }
    });
  }

  async onClickViewDetails() {
    if (this.params.isOverview) {
      sessionStorage.clear();
      // console.log('this.params', this.params.data);
      sessionStorage.clear();

      localStorage.setItem('platform', this.params.data.platform_id);
      this.storageService.$activePlatform.next(this.params.data.platform_id);
      this.storageService.kpiCache = null;
      this.storageService.$kpiData.next(null);

      this.routerService.navigateByUrl('/dashboard');
      // @ts-ignore
      // window.location = '/dashboard';
    } else if (this.params.isFormException) {
      // this.isFormException = this.params.isFormException;
    } else {
      this.dialogService.open(LeadDetailsDialogComponent, {
        header: '',
        data: this.params.data,
        width: '95vw',
        height: '90vw',
        modal: true,
        closable: true,
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw',
        },
      });
    }
  }
}
